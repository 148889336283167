import request from "@/Utils/api";

// 团队列表
export function teamList(data){
    return request({
        url: "api/v1_0_0.user_group",
        method: "post",
        data,
      });
  }
//   筛选
  export function listParams(data){
    return request({
        url: "api/v1_0_0.user_group/list_params",
        method: "post",
        cache:true,
        data,
      });
  }

  //  团队推荐
  export function recommendTeam(data){
    return request({
        url: "api/v1_0_0.user_group/recommend",
        method: "post",
        data,
      });
  }
   //  选择团队成员
   export function member_list(data){
    return request({
        url: "api/v1_0_0.user_group/member_list",
        method: "post",
        data,
      });
  }
   //  删除团队
   export function deleteTeam(data){
    return request({
        url: "api/v1_0_0.user_group/delete",
        method: "post",
        data,
      });
  }
   //  加入团队
   export function joinTeam(data){
    return request({
        url: "api/v1_0_0.user_group/join",
        method: "post",
        data,
      });
  }
  // 团队下的比赛、作业、考试
  export function get_matchs(data){
    return request({
        url: "api/v1_0_0.user_group/get_matchs",
        method: "post",
        data,
      });
  }
  // 团队下的考试
  export function get_tests(data){
    return request({
        url: "api/v1_0_0.user_group/get_tests",
        method: "post",
        data,
      });
  }
  // 团队下的作业
  export function get_tasks(data){
    return request({
        url: "api/v1_0_0.user_group/get_tasks",
        method: "post",
        data,
      });
  }

    // 团队下的作业
    export function get_quest_lists(data){
      return request({
          url: "api/v1_0_0.user_group/get_quest_lists",
          method: "post",
          data,
        });
    }

  

 
  
   // 创建团队
   export function createTeam(data){
    return request({
        url: "api/v1_0_0.user_group/create",
        method: "post",
        data,
      });
  }
  // 创建团队规则
  export function rule(data){
    return request({
        url: "api/v1_0_0.user_group/rule",
        method: "post",
        cache:true,
        data,
      });
  }

  // 团队详情
  export function teamDetail(data){
    return request({
        url: "api/v1_0_0.user_group/detail",
        method: "post",
        data,
      });
  }
  export function teamRanking(data){
    return request({
        url: "api/v1_0_0.user_group/get_members",
        method: "post",
        data,
      });
  }


  export function quit_group(data){
    return request({
        url: "api/v1_0_0.user_group/quit_group",
        method: "post",
        data,
      });
  }

  export function Team_is_cat(data){
    return request({
        url: "api/v1_0_0.user_group/remove_user",
        method: "post",
        data,
      });
  }
  // 添加成员
  export function append_member(data){
    return request({
        url: "api/v1_0_0.user_group/append_member",
        method: "post",
        data,
      });
  }



 
  
  
  