<template>
  <div>
    <el-card class="table-box">
      <form-create v-model="where" :label-width="'20px'" inline :components="[
        {name:'form-item',props:{prop:'keyword', placeholder:'请输入老师姓名 / 组员姓名'}},
    ]">
        <el-form-item class="operate" slot="after">
          <el-button type="primary" size="mini"  @click="search(where)">查询</el-button>
          <el-button type="primary" size="mini"  @click="search(where = {})">重置</el-button>
        </el-form-item>
      </form-create>

      <div class="operate" style="margin-bottom: 15px">
        <template v-if="teamUser.is_master">
          <el-button type="danger" size="mini" :disabled="!(data.length > 1) || userSelection.length <= 0" @click="$handleConfirm('你确定将这些用户移除分组吗？','/xapi/user.group/removeGroup',{...$route.params,user_ids:userSelection.map(d=>d.user_id)},search,{title:'移除分组'})">批量移除分组</el-button>
          <el-button type="danger" size="mini" :disabled="!(data.length > 1) || userSelection.length <= 0" @click="$refs.changeDialog.visibled(currentList = [...userSelection])">批量分组变更</el-button>
          <el-button type="danger" size="mini" @click="$refs.createDialog.visibled(true)">创建分组</el-button>
          <el-button type="danger" size="mini" :disabled="selection.length !== 1" @click="$handleConfirm('你确定删除该分组吗？','/xapi/user.group/delForm',{id:selection[0].id},getFormList,search,{title:'删除分组'})">删除分组</el-button>
          <el-button type="warning" :disabled="!$isPowers('teamManagerDrop') || userSelection.length === 0" size="mini" @click="$handleConfirm('你确定删除选中团员吗？','/xapi/user.group/quitGroup',{group_id:team_id,user_ids:userSelection.map(d=>d.user_id)},getFormList,search,{title:'删除成员'})">删除成员</el-button>
          <el-button type="warning" @click="addMember" size="mini">添加成员</el-button>
        </template>
        <template v-else>
          <el-button :disabled="!$isPowers('teamDrop')" type="danger" size="mini" @click="$handleConfirm('你确定退出该团队吗？','/api/v1_0_0.user_group/quit_group',{group_id:team_id},()=>{$handleRoute('TeamInfo')},{title:'退出团队'})">本人退出团队</el-button>
        </template>
      </div>
      <el-table
          ref="mainTable"
          :data="data"
          :show-header="false"
          @selection-change="selection = $event"
          :row-key="'id'"
      >
        <el-table-column type="selection" width="45" :selectable="(row, index)=>{return row.id > 0}" v-if="userInfo.id+''===teamUser.id+''"></el-table-column>
        <!-- <el-table-column label="编号" prop="id" width="80" align="center"></el-table-column> -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <!-- <com-table :ref="'comTable'+scope.row.id" :columns="[
              {type:'selection',hidden:!teamUser.is_master},
              {type:'index'},
              {prop:'realname',label:'名字',minWidth:'150' , align:'left'},
              {prop:'sex',label:'性别',width:'80' , align:'left'},
              {prop:'identity',label:'身份',width:'120' , align:'left',},
              {prop:'rank',label:'排名',minWidth:'80' , align:'left'},
              {prop:'grade',label:'成绩',width:'120' , align:'left'},
              {prop:'complate_lv',label:'完成度',width:'80' , align:'left'},
              {prop:'is_submit',label:'是否提交',width:'80' , align:'left'},
          ]"
                       :data="{url:'/xapi/user.group/memberList',params:{form_id:scope.row.id || 0,team_id}}"
                       @selection-change="userSelection = [...$event];"
                       :where="where"
                       :row-key="'user_id'"
                       :rowStyle="()=>{return {cursor:'pointer'};}"
                       @cell-click="(row)=>{teamUser.is_master && $handleRoute({user_id:row.user_id},'TeamUserInfoData')}"
            > -->
            <com-table :ref="'comTable'+scope.row.id" :columns="[
              {type:'selection',hidden:!teamUser.is_master},
              {type:'index'},
              {prop:'realname',label:'名字',width:'80' , align:'center'},
              {prop:'sex',label:'性别',width:'80' , align:'center'},
              {prop:'identity',label:'身份',width:'120' , align:'center',},
              {prop:'rank',label:'排名',minWidth:'50' , align:'center'},
              {prop:'grade',label:'成绩',width:'80' , align:'center'},
              {prop:'complate_lv',label:'完成度',width:'80' , align:'center'},
              {prop:'is_submit',label:'是否提交',width:'80' , align:'center'},
              {prop:'is_study',label:'是否学习课程',width:'120' ,slot: 'is_study' , align:'center'},
              {prop:'is_white',label:'是否白名单学员',width:'120' ,slot: 'is_white' , align:'center'},
              {prop:'is_drop',label:'是否退课',width:'80' ,slot: 'is_drop' , align:'center'},
          ]"
                       :data="{url:'/xapi/user.group/memberList',params:{form_id:scope.row.id || 0,team_id}}"
                       @selection-change="userSelection = [...$event];"
                       :where="where"
                       :row-key="'user_id'"
                       :rowStyle="()=>{return {cursor:'pointer'};}"
            >
              <template  v-if="teamUser.is_master">
                <el-table-column slot="after" fixed="right" label="操作" width="180" align="center">
                  <el-button-group slot-scope="{row}">
                    <el-button size="small"  :disabled="!(data.length > 1)" @click.stop="$refs.changeDialog.visibled(currentList = [row])">分组变更</el-button>
                    <el-button size="small" :disabled="!(scope.row.id > 0)"  @click.stop="$handleConfirm('你确定将该用户移除分组吗？','/xapi/user.group/removeGroup',{...$route.params,user_ids:[row.user_id]},search,{title:'移除分组'})">移除分组</el-button>
                  </el-button-group>
                </el-table-column>
              </template>
              <template slot="is_study" slot-scope="{row}" >
                <span>{{ row.is_study==1?'是':'否' }}</span>
              </template>
              <template slot="is_white" slot-scope="{row}" >
                <span>{{ row.is_white==1?'是':'否' }}</span>
              </template>
              <template slot="is_drop" slot-scope="{row}" >
                <span>{{ row.is_drop==1?'是':'否' }}</span>
              </template>
            </com-table>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" align="left"  >
        </el-table-column>
      </el-table>
    </el-card>

    <diy-dialog ref="createDialog" title="创建分组" center>
      <form-create ref="createForm" :components="[
            {name:'form-item',props:{prop:'group_name',label:'分组名称',required:true}},
            {name:'form-item',props:{prop:'user_ids',label:'选择成员', type:'data',showKey:'showname',columnKey:'user_id',multiple:true
                ,dialog:{
                  width:'60%',
                  url:'/xapi/user.group/memberList',
                  params:{form_id:0,team_id}
                },
                whereColumns:[
                  {name:'form-item',props:{prop:'keyword',label:'关键字',placeholder:'请输入学生姓名',style:{width:'550px'}}},
                ],
                columns:[
                  {prop:'user_id',label:'ID',width:'80' , align:'center'},
                  {prop:'showname',label:'学生姓名',minWidth:'120px', align:'center' },
                  {prop:'sex',label:'性别',width:'140' , align:'center'},
                ],
              }
            },
          ]" :label-width="'100px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.createDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="$refs.createForm.validate((data,valid)=>{
        if(valid){
          $http.post('/xapi/user.group/createForm' , {...$route.params,...(data || {})}).then(({msg})=>{
               $handleMessage(msg || '操作成功！' , 'success' , ()=>{ getFormList();search(); $refs.createDialog.visibled(false);})
              }).catch(()=>{})
        }
      })">确 定</el-button>
      </div>
    </diy-dialog>
    <diy-dialog ref="changeDialog" title="分组变更" center>
      <form-create ref="changeForm" :components="[
            {name:'form-item',props:{prop:'id',label:'选择分组',required:true,type:'select',filterable:false,data:data.filter(d=>d.id>0).map(d=>{return {label:d.name,value:d.id}})}},
          ]" :label-width="'80px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.changeDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="$refs.changeForm.validate((data,valid)=>{
        if(valid){
          $http.post('/xapi/user.group/changeForm' , {...$route.params,...(data || {}),user_ids:currentList.map(d=>d.user_id)}).then(({msg})=>{
                        $handleMessage(msg || '操作成功！' , 'success' , ()=>{search(); $refs.changeDialog.visibled(false) })
              }).catch(()=>{})
        }
      })">确 定</el-button>
      </div>
    </diy-dialog>

    <!-- 添加成员 -->
    <el-dialog
      class="dialogVisible"
      title="添加团队成员"
      :visible.sync="dialogVisible"
      width="70%"
      :show-close="true"
    >
      <div class="body">
        <div style="display: flex;align-items: center;" >
            <el-input
            style="width: 20%;margin-left: 20px;"
            @change="searchMember"
            @keyup.enter.native="searchMember"
              v-model="searchStu"
              clearable
              placeholder="请输入学生姓名或手机号"
            ></el-input>

        </div>
            <el-table
            v-loading="memberLoading"
              stripe
              type="selection"
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              @selection-change="selectPeople"
              :row-key="(row) => row.id"
            >
              <el-table-column
                type="selection"
                align="center"
                :reserve-selection="true"
              >
              </el-table-column>
              <el-table-column label="ID" align="center">
                <template slot-scope="scope">{{ scope.row.id }}</template>
              </el-table-column>
              <el-table-column prop="realname" label="学生" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="手机号" align="center">
              </el-table-column>
              <el-table-column prop="sex" label="性别" align="center">
              </el-table-column>
            </el-table>
        <div class="select">已选择：{{ selectPeopleList.length }}</div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="getMemberList((page = $event))"
            :page-size="pageSize"
            :current-page="page"
            :hide-on-single-page="true"
          >
          </el-pagination>
        </div>
          <div slot="footer" class="dialog-footer">
            <el-button   @click="cancel">取消</el-button>
            <el-button type="primary"  @click="submit">确认</el-button>
          </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import DiyDialog from "@/components/com/diyDialog";
import {
  member_list,
  append_member
} from "@/api/team.js";
export default {
  props: {
    team_id:{
      default: () => 0,
    },
    teamUser:{
      type:Object,
      default: () => { return {}; },
    },
  },
  components: {DiyDialog},
  data(){
    return {
      where:{},
      data:[{id:'',name:'未分组'}],
      loading:false,
      currentList:[],
      selection:[],
      userSelection:[],
      userSelectionC:[],
      tableData:[],
      dialogVisible:false,//添加成员弹框
      page: 1,
      pageSize: 10,
      total: 0,
      searchStu: "", //搜索值
      memberLoading:false,
      selectPeopleList: [],

    };
  },
  methods:{
    routeUpdate(){
      this.getFormList();
      this.search();
      // console.log(this.teamUser,"userSelectionuserSelection");
      // :data="{url:'/xapi/user.group/memberList',params:{form_id:scope.row.id || 0,team_id}}"
      // this.$http.post('/xapi/user.group/memberList',{team_id:this.team_id,page:1}).then(res=>{
      //     console.log(res,"resssssss");
      // })
    },
   
    search(where){
      where = where || this.where
      for (let i = 0; i < this.data.length; i++) {
        let $refs = this.$refs['comTable'+this.data[i].id]
        if($refs){
          $refs.reset(where);
        }
      }
    },
   
    getFormList(){
      this.loading = true;
      this.search();

      this.$http.post('/xapi/user.group/formList' , {team_id:this.team_id})
          .then(({data})=>{
            // this.data = [{id:'',name:'未分组'}].concat(data || [])
            this.data = data.concat([{id:'',name:'未分组'}])
            this.$nextTick(()=>{
              this.$refs.mainTable.toggleRowExpansion(this.data[0] , true);
            })
          }).catch(e=>{
      }).finally(()=>{
        this.loading = false;
      })
    },
    addMember(){
      console.log('添加成员')
      this.searchStu=""
      this.dialogVisible=true
      this.getMemberList()
    },
    searchMember(){
      this.getMemberList()
    },
    // 成员列表
    getMemberList() {
      this.memberLoading=true
      member_list({
        page: this.page,
        page_size: this.pageSize,
        keyword: this.searchStu,
        un_group_id:this.team_id
      })
        .then(({ data }) => {
          console.log(data, "成员列表成功");
      this.memberLoading=false

          this.tableData = [...data.data];
          this.total = data.total;
        })
        .catch((err) => {
          console.log(err, "成员列表失败");
        });
    },
     // 选择成员的函数
     selectPeople(val) {
      this.selectPeopleList = val;
    },
     // 取消
     cancel() {
      this.dialogVisible = false;
      this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
    },
    // 确定成员
    submit() {
      console.log(this.selectPeopleList,"this.selectPeopleList")
      let data={
        id:this.team_id,
        members:this.selectPeopleList.map(v=>v.id)
      }
      append_member(data).then(res=>{
        console.log(res,"res")
        this.$message.success(res.msg)
        this.dialogVisible = false;
        this.getFormList();
      })
    },
  }
}
</script>

<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.dialog-footer{
  display: flex;
  justify-content: flex-end;
}
.save,
    .cancel {
      float: right;
      bottom: 2rem;
      right: 2rem;
      width: 40px;
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      padding: 0px 16px;
      text-align: center;
      width: 74px;
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
    }
    .cancel {
      margin-right: 2rem;
      color: #333333;
      background: #ffffff;
      border: 1px solid #979797;
    }
    .pagination {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
  }
</style>